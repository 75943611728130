'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.devAssert = devAssert;

function devAssert(condition, message) {
  const booleanCondition = Boolean(condition);

  if (!booleanCondition) {
    throw new Error(message);
  }
}
